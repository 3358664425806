import React from "react";
import { graphql } from "gatsby"
import Layout from "../components/Layout";

export default function Template({ data }) {
    const { markdownRemark } = data;
    const { frontmatter, html } = markdownRemark;

    return (
        <Layout>
            <main className="static">
                <div className="row">
                
                <h1>{frontmatter.title}</h1>
                <div
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                </div>
            </main>
        </Layout>
    );
}


export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`